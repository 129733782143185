<template>
  <div class="container">
    <!-- 面包屑 -->
    <BaseBread>
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>生态合作</BaseBreadItem>
    </BaseBread>
    <!-- 单图片 -->
    <BaseOneImage :dataSource="dataSource" />
     <!--文本分界线 begin-->
     <div class="text-split-line">
      <div class="info">
        <p>OUR POINT 为您的机械系统保驾护航</p>
        <p>
          ----------------------始终相信只有专注才能成就杰出----------------------
        </p>
      </div>
    </div>
    <PlatFormInfo></PlatFormInfo>
    <BaseOneImage :dataSource="dataSource2" />
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import useStore from "@/store";
import PlatFormInfo from "./components/platform-info.vue";
import { findAd } from '@/api/home'
import { ref } from 'vue'
// // 获取当前路由对象
// const route = useRoute();
// // 获取动态路由参数的name
// const name = route.query.name;

export default {
  name: 'HomeBanner',
  components: { PlatFormInfo },
  setup () {
    const dataSource = ref([])
    const dataSource2 = ref([])
    findAd("techplatImage").then(data => {
      dataSource.value = data.data.list[0]
    })
    findAd("techplatImage2").then(data => {
      dataSource2.value = data.data.list[0]
    })
    return { dataSource,dataSource2 }
  }
}

</script>

<style scoped lang="less">
.text-split-line {
  background: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  height: 160px;
  padding: 0 80px;
  .info {
    padding-left: 10px;
    flex: 1;
    p {
      &:first-child {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 18px;
      }
      &:last-child {
        color: #999;
        font-size: 16px;
      }
    }
  }
}
</style>
