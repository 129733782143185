<template>
      <div ref="target" style="position: relative;height: 426px;">
        <!-- 面板内容 -->
        <Transition name="fade">
          <ul  class="goods-list">
            <li>
              <RouterLink :to="`/`">
                <img src="@/assets/images/11.png" alt="">
                <p class="name ellipsis">数万用户的共同选择</p>
                <p class="price">新用户专享</p>
              </RouterLink>
            </li>
            <li>
              <RouterLink :to="`/`">
                <img src="@/assets/images/22.png" alt="">
                <p class="name ellipsis">打造多样化终端</p>
                <p class="price">多终端独立设计</p>
              </RouterLink>
            </li>
            <li>
              <RouterLink :to="`/`">
                <img src="@/assets/images/33.png" alt="">
                <p class="name ellipsis">展示不同效果</p>
                <p class="price">支持多种营销插件</p>
              </RouterLink>
            </li>
            <li>
              <RouterLink :to="`/`">
                <img src="@/assets/images/44.png" alt="">
                <p class="name ellipsis">助力快速构建网站</p>
                <p class="price">SaaS系统</p>
              </RouterLink>
            </li>
            <li>
              <RouterLink :to="`/`">
                <img src="@/assets/images/55.png" alt="">
                <p class="name ellipsis">预定优惠名额</p>
                <p class="price">多种营销插件</p>
              </RouterLink>
            </li>
          </ul>
        
        </Transition>
      </div>
</template>
<script>

export default {
  name: 'HomeNew',
  components: {  },
  setup () {
  }
}
</script>
<style scoped lang="less">
.goods-list {
  display: flex;
  justify-content: space-between;
  height: 346px;
  li {
    width: 240px;
    height: 346px;
    background: #fff;
    .hoverShadow();
    img {
      width: 130px;
      height: 130px;
      margin: 50px;
    }
    p {
      font-size: 18px;
      padding: 12px 30px 0 30px;
      text-align: center;
    }
    .price {
      color: @priceColor;
    }
  }
}
</style>
